@font-face {
  font-family: "SourceHanSans";
  src: url("../fonts/SourceHanSans-Normal.ttf");
}
p {
  font-family: "SourceHanSans", sans-serif;
  font-weight: normal;
  font-size: 12px;
}

span[color=Title] {
  color: #948C2F;
}
span[color=Highlight] {
  color: #E0CE07;
}
span[color=Fire] {
  color: #f0744e;
}
span[color=Ice] {
  color: #41aefb;
}
span[color=Wind] {
  color: #55ffb5;
}
span[color=Thunder] {
  color: #b46bff;
}
span[color=Light] {
  color: #f8e56c;
}
span[color=Dark] {
  color: #e649a6;
}

div.slider-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 20px;
  gap: 0.75em;
}
div.slider-container div.slider-button {
  cursor: pointer;
  width: 16px;
  height: 16px;
}
div.slider-container div.slider-button svg {
  fill: #eeeeee;
  width: 100%;
  height: 100%;
}
div.slider-container div.slider-bar {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 7px;
  margin-bottom: 7px;
  width: 85%;
  user-select: none;
}
div.slider-container div.slider-bar div.slider-bar-progress {
  background-color: #c9c9c9;
  height: 6px;
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
}
div.slider-container div.slider-bar div.slider-bar-remaining {
  background-color: #494949;
  height: 6px;
  border-top-right-radius: 1em;
  border-bottom-right-radius: 1em;
}
div.slider-container div.slider-bar div.slider-bar-handle {
  position: absolute;
  width: 14.14px;
  height: 14.14px;
  rotate: 45deg;
  background-color: #eeeeee;
  border-top-right-radius: 50%;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 10%;
  border-bottom-right-radius: 10%;
  z-index: 20;
}
div.slider-container div.slider-bar div.slider-bar-handle:hover {
  cursor: ew-resize;
}

div.resonator-kit-info div.abilities div.resonator-abilities-description, div.resonator-kit-info div.abilities div.resonator-base-description {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  width: 100%;
  border: 2px solid black;
  border-radius: 1em;
  background-color: #303030;
}

div.resonator-kit-info div.abilities div.resonator-abilities-description div.skill-description-container div.skill-scalings-container div.skill-level-container div.level-text input#lvl-input, div.resonator-kit-info div.abilities div.resonator-base-description div.resonator-base-stats div.stats-adjuster div.level-container div.level-text input#lvl-input, div.resonator-kit-info div.abilities div.resonator-abilities-description div.skill-description-container div.skill-scalings-container div.skill-level-container div.level-text input#ability-lvl-input, div.resonator-kit-info div.abilities div.resonator-base-description div.resonator-base-stats div.stats-adjuster div.level-container div.level-text input#ability-lvl-input {
  font-family: "SourceHanSans", sans-serif;
  font-weight: bold;
  font-size: inherit;
  color: inherit;
  background-color: inherit;
  width: 2em;
  height: 1.2em;
  text-align: center;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0;
}
div.resonator-kit-info div.abilities div.resonator-abilities-description div.skill-description-container div.skill-scalings-container div.skill-level-container div.level-text input[type=number], div.resonator-kit-info div.abilities div.resonator-base-description div.resonator-base-stats div.stats-adjuster div.level-container div.level-text input[type=number] {
  -moz-appearance: textfield;
}
div.resonator-kit-info div.abilities div.resonator-abilities-description div.skill-description-container div.skill-scalings-container div.skill-level-container div.level-text input:focus, div.resonator-kit-info div.abilities div.resonator-base-description div.resonator-base-stats div.stats-adjuster div.level-container div.level-text input:focus {
  outline-width: 0;
}
div.resonator-kit-info div.abilities div.resonator-abilities-description div.skill-description-container div.skill-scalings-container div.skill-level-container div.level-text input::-webkit-inner-spin-button, div.resonator-kit-info div.abilities div.resonator-base-description div.resonator-base-stats div.stats-adjuster div.level-container div.level-text input::-webkit-inner-spin-button, div.resonator-kit-info div.abilities div.resonator-abilities-description div.skill-description-container div.skill-scalings-container div.skill-level-container div.level-text input input::-webkit-outer-spin-button, div.resonator-kit-info div.abilities div.resonator-base-description div.resonator-base-stats div.stats-adjuster div.level-container div.level-text input input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

div.resonator-kit-info div.abilities div.resonator-abilities-description div.skill-description-container div.inherent-skill-container div.skill-name p, div.resonator-kit-info div.abilities div.resonator-abilities-description div.skill-description-container div.outro-skill-container div.skill-name p, div.resonator-kit-info div.abilities div.resonator-abilities-description div.skill-description-container div.skill-container div.skill-name p {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

div.resonator-kit-info div.abilities div.resonator-abilities-description div.skill-description-container div.inherent-skill-container div.skill-description p, div.resonator-kit-info div.abilities div.resonator-abilities-description div.skill-description-container div.outro-skill-container div.skill-description p, div.resonator-kit-info div.abilities div.resonator-abilities-description div.skill-description-container div.skill-container div.skill-description p {
  font-size: 16px;
}
div.resonator-kit-info div.abilities div.resonator-abilities-description div.skill-description-container div.inherent-skill-container div.skill-description p span[color=Title], div.resonator-kit-info div.abilities div.resonator-abilities-description div.skill-description-container div.outro-skill-container div.skill-description p span[color=Title], div.resonator-kit-info div.abilities div.resonator-abilities-description div.skill-description-container div.skill-container div.skill-description p span[color=Title] {
  font-size: 18px;
}
div.resonator-kit-info div.abilities div.resonator-abilities-description div.skill-description-container div.inherent-skill-container div.skill-description p span[color=Highlight], div.resonator-kit-info div.abilities div.resonator-abilities-description div.skill-description-container div.outro-skill-container div.skill-description p span[color=Highlight], div.resonator-kit-info div.abilities div.resonator-abilities-description div.skill-description-container div.skill-container div.skill-description p span[color=Highlight] {
  font-weight: bold;
}

body {
  font-family: "SourceHanSans", sans-serif;
  font-weight: normal;
  scrollbar-width: 10px;
  background-color: #222222;
  color: #dedede;
  margin: 0;
}
body ::-webkit-scrollbar {
  width: 8px;
}
body ::-webkit-scrollbar-track {
  background: #131313;
}
body ::-webkit-scrollbar-thumb {
  background-color: #696969;
  border-radius: 4px;
}
body ::-webkit-scrollbar-thumb:hover {
  background-color: #9b9b9b;
}

div.flex-container {
  display: flex;
  flex-direction: row;
}

div.navbar {
  background-color: #141414;
  padding-left: 1em;
  padding-right: 1em;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
}
div.navbar div.navbar-pin-left {
  margin: 0;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-content: center;
  left: 1em;
  gap: 1em;
}
div.navbar div.navbar-pin-left img {
  width: 40px;
  height: 40px;
  border: 1px solid;
  border-color: #e2e2e2;
  border-radius: 20px;
}
div.navbar div.navbar-pin-left h1 {
  font-size: 20px;
  margin: 0;
  align-self: center;
}
div.navbar div.flex-container {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
div.navbar div.flex-container div.navbar-button {
  width: 100px;
  text-align: center;
  font-size: 10px;
  transition-property: font-size;
  transition-duration: 150ms;
}
div.navbar div.flex-container div.navbar-button:hover {
  cursor: pointer;
  font-size: px;
  transition-duration: 350ms;
}

div.interface {
  height: calc(100vh - 30px - 50px);
}

div.resonator-kit-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: calc(100vh - 30px - 50px);
  width: 100%;
}
div.resonator-kit-info div.selector {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1em;
  flex-wrap: wrap;
  overflow-y: scroll;
  padding: 10px;
  width: min(40%, 650px);
  height: calc(100vh - 30px - 50px - 20px);
}
div.resonator-kit-info div.selector div.resonator-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  border: 2px solid black;
  background-color: rgba(214, 214, 214, 0);
  transition-property: border-color, background-color;
  transition-duration: 150ms;
  width: 20%;
  min-width: calc(120px + 1.5em);
  min-height: calc(120px + 1.5em);
  box-shadow: 4px 4px 4px black;
}
div.resonator-kit-info div.selector div.resonator-option:hover {
  cursor: pointer;
  border-color: #e2e2e2;
  background-color: rgba(214, 214, 214, 0.2274509804);
}
div.resonator-kit-info div.selector div.resonator-option img {
  display: block;
  height: min(100%, width);
  width: 100%;
}
div.resonator-kit-info div.selector div.resonator-option div.name-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #3b3b3b;
  width: 100%;
  height: 1.5em;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
div.resonator-kit-info div.selector div.resonator-option div.name-container p {
  font-size: 1em;
  margin: 0;
}
div.resonator-kit-info div.selector div.resonator-option div.name-container p[oversized=true] {
  font-size: 0.8em;
}
div.resonator-kit-info div.selector div.resonator-option[data-selected=true] {
  border-color: #fffc69;
  background-color: rgba(214, 214, 214, 0.2274509804);
  transition-property: border-color, background-color;
  transition-property: all;
  transition-duration: 150ms;
}
div.resonator-kit-info div.selector div.resonator-option[data-selected=true]:hover {
  border-color: #fffc69;
  background-color: rgba(214, 214, 214, 0.2274509804);
}
div.resonator-kit-info div.abilities {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.5em;
  width: calc(96% - (min(40%, 650px) - 20px));
  padding: 10px;
  background-color: #272727;
}
div.resonator-kit-info div.abilities div[change-hover-color=true] {
  transition-property: border-color;
  transition-duration: 250ms;
}
div.resonator-kit-info div.abilities div[change-hover-color=true]:hover {
  border-color: #cac863;
}
div.resonator-kit-info div.abilities div.resonator-base-description {
  height: 196px;
}
div.resonator-kit-info div.abilities div.resonator-base-description div.icons {
  margin: 0.5em;
}
div.resonator-kit-info div.abilities div.resonator-base-description div.icons div.resonator-icon-small img {
  border: 2px solid #e2e2e2;
  border-radius: 100%;
  width: 160px;
  height: 160px;
}
div.resonator-kit-info div.abilities div.resonator-base-description div.resonator-base-stats {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  width: calc(100% - 160px);
  margin: 0.25em;
}
div.resonator-kit-info div.abilities div.resonator-base-description div.resonator-base-stats div.stats-adjuster {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: max(300px, 50%);
  gap: 2px;
  margin: 8px;
}
div.resonator-kit-info div.abilities div.resonator-base-description div.resonator-base-stats div.stats-adjuster div.name-element-weapon-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100%;
}
div.resonator-kit-info div.abilities div.resonator-base-description div.resonator-base-stats div.stats-adjuster div.name-element-weapon-container h2 {
  align-self: flex-start;
  margin: 0;
  font-size: 36px;
}
div.resonator-kit-info div.abilities div.resonator-base-description div.resonator-base-stats div.stats-adjuster div.name-element-weapon-container div.element-weapon-container {
  position: relative;
  left: -3.5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: flex-start;
  align-self: flex-start;
}
div.resonator-kit-info div.abilities div.resonator-base-description div.resonator-base-stats div.stats-adjuster div.name-element-weapon-container div.element-weapon-container img {
  width: 28px;
  height: 28px;
}
div.resonator-kit-info div.abilities div.resonator-base-description div.resonator-base-stats div.stats-adjuster div.level-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
  text-transform: uppercase;
  width: 100%;
  height: 100%;
  gap: 3px;
}
div.resonator-kit-info div.abilities div.resonator-base-description div.resonator-base-stats div.stats-adjuster div.level-container div.ascension-stars {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 6px;
  padding-left: 2px;
}
div.resonator-kit-info div.abilities div.resonator-base-description div.resonator-base-stats div.stats-adjuster div.level-container div.ascension-stars div {
  width: 11.313708499px;
  height: 11.313708499px;
  rotate: 45deg;
  border: 1px solid white;
  border-radius: 20%;
  background-color: #494949;
}
div.resonator-kit-info div.abilities div.resonator-base-description div.resonator-base-stats div.stats-adjuster div.level-container div.ascension-stars div.ascension-fill {
  background-color: white;
}
div.resonator-kit-info div.abilities div.resonator-base-description div.resonator-base-stats div.stats-adjuster div.level-container div.level-text {
  font-size: 24px;
}
div.resonator-kit-info div.abilities div.resonator-base-description div.resonator-base-stats div.stats-adjuster div.level-container div.level-text span {
  font-family: "SourceHanSans", sans-serif;
  font-weight: bold;
  font-size: inherit;
}
div.resonator-kit-info div.abilities div.resonator-base-description div.resonator-base-stats div.stats-adjuster div.level-container div.slider-container {
  max-width: 300px;
}
div.resonator-kit-info div.abilities div.resonator-base-description div.resonator-base-stats div.stats-display {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 1em;
  width: calc(100% - max(300px, 50%));
}
div.resonator-kit-info div.abilities div.resonator-base-description div.resonator-base-stats div.stats-display div {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border-radius: 10px;
  border: 2px solid black;
  background-color: #494949;
  gap: 1em;
  height: 20%;
}
div.resonator-kit-info div.abilities div.resonator-base-description div.resonator-base-stats div.stats-display div p {
  font-size: 20px;
  text-align: center;
  margin: 0;
}
div.resonator-kit-info div.abilities div.resonator-abilities-description {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  width: calc(100% - 20px);
  min-height: calc(100vh - 30px - 50px - (1em + 200px + 20px) - 20px);
  gap: 10px;
}
div.resonator-kit-info div.abilities div.resonator-abilities-description div.skill-category-selector {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: min(100%, 650px);
  height: 35px;
  gap: 10px;
  border-radius: 1em;
}
div.resonator-kit-info div.abilities div.resonator-abilities-description div.skill-category-selector div.ability-select-option {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
  border: 2px solid black;
  border-radius: 10px;
  transition-property: background-color border-color;
  transition-duration: 150ms;
}
div.resonator-kit-info div.abilities div.resonator-abilities-description div.skill-category-selector div.ability-select-option[data-selected=true] {
  background-color: #494949;
  border-color: #fffc69;
}
div.resonator-kit-info div.abilities div.resonator-abilities-description div.skill-category-selector div.ability-select-option:hover {
  cursor: pointer;
}
div.resonator-kit-info div.abilities div.resonator-abilities-description div.skill-category-selector div.ability-select-option p {
  margin: 0;
  font-size: 12px;
  text-align: center;
}
div.resonator-kit-info div.abilities div.resonator-abilities-description div.skill-description-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 30px - 50px - (1em + 200px + 20px) - 20px);
  gap: 0.5em;
}
div.resonator-kit-info div.abilities div.resonator-abilities-description div.skill-description-container div[change-hover-color=true] {
  transition-property: border-color;
  transition-duration: 250ms;
}
div.resonator-kit-info div.abilities div.resonator-abilities-description div.skill-description-container div[change-hover-color=true]:hover {
  border-color: #cac863;
}
div.resonator-kit-info div.abilities div.resonator-abilities-description div.skill-description-container div.skill-container {
  width: calc(60% - 20px);
  height: calc(100% - 20px - 35px);
  padding: 10px;
  overflow-y: auto;
  border: 2px solid black;
  border-radius: 10px;
  background-color: #272727;
}
div.resonator-kit-info div.abilities div.resonator-abilities-description div.skill-description-container div.outro-skill-container {
  width: calc(100% - 20px);
  height: calc(100% - 20px - 35px);
  padding: 10px;
  overflow-y: auto;
  border: 2px solid black;
  border-radius: 10px;
  background-color: #272727;
}
div.resonator-kit-info div.abilities div.resonator-abilities-description div.skill-description-container div.skill-scalings-container {
  width: calc(40% - 20px);
  height: calc(100% - 20px - 35px);
  padding: 10px;
  overflow-y: auto;
  border: 2px solid black;
  border-radius: 10px;
  background-color: #272727;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
div.resonator-kit-info div.abilities div.resonator-abilities-description div.skill-description-container div.skill-scalings-container div.skill-level-container div.level-text span {
  font-family: "SourceHanSans", sans-serif;
  font-weight: bold;
  font-size: 20px;
  text-transform: uppercase;
}
div.resonator-kit-info div.abilities div.resonator-abilities-description div.skill-description-container div.skill-scalings-container div.skill-level-container div.level-text input#ability-lvl-input {
  font-family: "SourceHanSans", sans-serif;
  font-weight: bold;
  font-size: 20px;
}
div.resonator-kit-info div.abilities div.resonator-abilities-description div.skill-description-container div.skill-scalings-container table.scalings-table {
  border-collapse: collapse;
}
div.resonator-kit-info div.abilities div.resonator-abilities-description div.skill-description-container div.skill-scalings-container table.scalings-table tr td {
  border: 1px solid;
  font-size: min(0.75vw, 14px);
  padding: 3px;
}
div.resonator-kit-info div.abilities div.resonator-abilities-description div.skill-description-container div.inherent-skill-container {
  width: calc(50% - 20px);
  height: calc(100% - 20px - 35px);
  padding: 10px;
  overflow-y: auto;
  border: 2px solid black;
  border-radius: 10px;
  background-color: #272727;
}

div.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 30px;
  background-color: #141414;
}
div.footer .flex-container {
  justify-content: space-evenly;
  align-items: center;
  height: 30px;
}
div.footer .flex-container p {
  font-size: 10px;
}